import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BlockbackService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return this._window();
    }
  }
  nobackbutton(){
    // // console.log("work");
    window.location.hash="";
    window.location.hash=".";//again because google chrome don't insert first hash into history
    window.onhashchange=function(){window.location.hash="";}
  }
   _window(): any {
    // return the global native browser window object
    return window;
  }
}
