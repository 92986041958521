import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CanactivateGuard } from './canactivate.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./common/Api/menu/menu.module').then( m => m.MenuPageModule),
    canActivate:[CanactivateGuard]
  },
  {
    path: 'forgot',
    loadChildren: () => import('./forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'confirmmail',
    loadChildren: () => import('./confirmmail/confirmmail.module').then( m => m.ConfirmmailPageModule)
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  },  {
    path: 'login-history',
    loadChildren: () => import('./login-history/login-history.module').then( m => m.LoginHistoryPageModule)
  },

  // {
  //   path: 'con-report-chart-preview',
  //   loadChildren: () => import('./con-report-chart-preview/con-report-chart-preview.module').then( m => m.ConReportChartPreviewPageModule)
  // },

  // {
  //   path: 'how-to-use',
  //   loadChildren: () => import('./how-to-use/how-to-use.module').then( m => m.HowToUsePageModule)
  // },

  // {
  //   path: 'siteapproval',
  //   loadChildren: () => import('./siteapproval/siteapproval.module').then( m => m.SiteapprovalPageModule)
  // },

  // {
  //   path: 'siteapproval-list',
  //   loadChildren: () => import('./siteapproval-list/siteapproval-list.module').then( m => m.SiteapprovalListPageModule)
  // },

  // {
  //   path: 'auditorstatus',
  //   loadChildren: () => import('./auditorstatus/auditorstatus.module').then( m => m.AuditorstatusPageModule)
  // },

  // {
  //   path: 'about-us',
  //   loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsPageModule)
  // },

  // {
  //   path: 'chartpreview-column',
  //   loadChildren: () => import('./chartpreview-column/chartpreview-column.module').then( m => m.ChartpreviewColumnPageModule)
  // },
  // {
  //   path: 'chartpreview-line',
  //   loadChildren: () => import('./chartpreview-line/chartpreview-line.module').then( m => m.ChartpreviewLinePageModule)
  // },

  // {
  //   path: 'chartpreview',
  //   loadChildren: () => import('./chartpreview/chartpreview.module').then( m => m.ChartpreviewPageModule)
  // },

  // {
  //   path: 'nowork',
  //   loadChildren: () => import('./nowork/nowork.module').then( m => m.NoworkPageModule)
  // },

  // {
  //   path: 'totalupload',
  //   loadChildren: () => import('./totalupload/totalupload.module').then( m => m.TotaluploadPageModule)
  // },

  // {
  //   path: 'sitemanagercontractorauditorlist',
  //   loadChildren: () => import('./sitemanagercontractorauditorlist/sitemanagercontractorauditorlist.module').then( m => m.SitemanagercontractorauditorlistPageModule)
  // },

  // {
  //   path: 'sitemanagerdashboard',
  //   loadChildren: () => import('./sitemanagerdashboard/sitemanagerdashboard.module').then( m => m.SitemanagerdashboardPageModule)
  // },

  // {
  //   path: 'projectmanagerauditorcontractorlist',
  //   loadChildren: () => import('./projectmanagerauditorcontractorlist/projectmanagerauditorcontractorlist.module').then( m => m.ProjectmanagerauditorcontractorlistPageModule)
  // },

  // {
  //   path: 'projectmanagerdashboard',
  //   loadChildren: () => import('./projectmanagerdashboard/projectmanagerdashboard.module').then( m => m.ProjectmanagerdashboardPageModule)
  // },

  // {
  //   path: 'clientauditorcontractorlist',
  //   loadChildren: () => import('./clientauditorcontractorlist/clientauditorcontractorlist.module').then( m => m.ClientauditorcontractorlistPageModule)
  // },

  // {
  //   path: 'clientauditorlist',
  //   loadChildren: () => import('./clientauditorlist/clientauditorlist.module').then( m => m.ClientauditorlistPageModule)
  // },

  // {
  //   path: 'clientdashboard',
  //   loadChildren: () => import('./clientdashboard/clientdashboard.module').then( m => m.ClientdashboardPageModule)
  // },

  // {
  //   path: 'report-log',
  //   loadChildren: () => import('./report-log/report-log.module').then( m => m.ReportLogPageModule)
  // },

  // {
  //   path: 'auditordashboardnotupload',
  //   loadChildren: () => import('./auditordashboardnotupload/auditordashboardnotupload.module').then( m => m.AuditordashboardnotuploadPageModule)
  // },

  // {
  //   path: 'auditordashboard-contractorlist',
  //   loadChildren: () => import('./auditordashboard-contractorlist/auditordashboard-contractorlist.module').then( m => m.AuditordashboardContractorlistPageModule)
  // },

  // {
  //   path: 'superadminyettouploadyettovalidate',
  //   loadChildren: () => import('./superadminyettouploadyettovalidate/superadminyettouploadyettovalidate.module').then( m => m.SuperadminyettouploadyettovalidatePageModule)
  // },

  // {
  //   path: 'superadmindasboardlist',
  //   loadChildren: () => import('./superadmindasboardlist/superadmindasboardlist.module').then( m => m.SuperadmindasboardlistPageModule)
  // },

  // {
  //   path: 'month',
  //   loadChildren: () => import('./contractor total-location/month/month.module').then( m => m.MonthPageModule)
  // },

  // {
  //   path: 'repositoryviewdetails',
  //   loadChildren: () => import('./repositoryviewdetails/repositoryviewdetails.module').then( m => m.RepositoryviewdetailsPageModule)
  // },

  // {
  //   path: 'repository',
  //   loadChildren: () => import('./repository/repository.module').then( m => m.RepositoryPageModule)
// }
// {
  //   path: 'contractorupload-datails',
  //   loadChildren: () => import('./contractorupload-datails/contractorupload-datails.module').then( m => m.ContractoruploadDatailsPageModule)
  // },

  // {
  //   path: 'statusviewdetails-contractor',
  //   loadChildren: () => import('./statusviewdetails-contractor/statusviewdetails-contractor.module').then( m => m.StatusviewdetailsContractorPageModule)
  // },

  // {
  //   path: 'contractorviewreport',
  //   loadChildren: () => import('./contractorviewreport/contractorviewreport.module').then( m => m.ContractorviewreportPageModule)
  // },

  // {
  //   path: 'admindashboard',
  //   loadChildren: () => import('./admindashboard/admindashboard.module').then( m => m.AdmindashboardPageModule)
  // },
  // {
  //   path: 'adminadduser',
  //   loadChildren: () => import('./adminadduser/adminadduser.module').then( m => m.AdminadduserPageModule)
  // },

  // {
  //   path: 'contractorlist',
  //   loadChildren: () => import('./contractorlist/contractorlist.module').then( m => m.ContractorlistPageModule)
  // },

  // {
  //   path: 'auditorvalidationlist',
  //   loadChildren: () => import('./auditorvalidationlist/auditorvalidationlist.module').then( m => m.AuditorvalidationlistPageModule)
  // },




  // {
  //   path: 'auditordashboard',
  //   loadChildren: () => import('./auditordashboard/auditordashboard.module').then( m => m.AuditordashboardPageModule)
  // },

  // {
  //   path: 'rejectedreason',
  //   loadChildren: () => import('./rejectedreason/rejectedreason.module').then( m => m.RejectedreasonPageModule)
  // },
  // {
  //   path: 'deviationobserved',
  //   loadChildren: () => import('./deviationobserved/deviationobserved.module').then( m => m.DeviationobservedPageModule)
  // },
  // {
  //   path: 'approved',
  //   loadChildren: () => import('./approved/approved.module').then( m => m.ApprovedPageModule)
  // },

  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  // },

  // {
  //   path: 'contractor',
  //   loadChildren: () => import('./contractor/contractor.module').then( m => m.ContractorPageModule)
  // },
  // {
  //   path: 'auditor',
  //   loadChildren: () => import('./auditor/auditor.module').then( m => m.AuditorPageModule)
  // },
  // {
  //   path: 'report',
  //   loadChildren: () => import('./report/report.module').then( m => m.ReportPageModule)
  // },
  // {
  //   path: 'reject',
  //   loadChildren: () => import('./reject/reject.module').then( m => m.RejectPageModule)
  // },
  // {
  //   path: 'notification',
  //   loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  // },
  // {
  //   path: 'menu',
  //   loadChildren: () => import('./common/Api/menu/menu.module').then( m => m.MenuPageModule)
  // },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
