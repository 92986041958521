import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-erroralert',
  templateUrl: './erroralert.component.html',
  styleUrls: ['./erroralert.component.scss'],
})
export class ErroralertComponent implements OnInit {


@Input() ErrorMsg:string;
error1:boolean;
error2:boolean;


  constructor(public popovercontroller:PopoverController,public nav:NavController) { }

  ngOnInit() {
    // // console.log(this.ErrorMsg);
    if(this.ErrorMsg == undefined){
      // // console.log("work")
      this.error1=false;
      this.error2=true;
    }
    else if(this.ErrorMsg != undefined){
      this.error1=true;
      this.error2=false;
    }
  }


  Alertdismiss(){

    this.popovercontroller.dismiss();


  }

}
