import { Component } from '@angular/core';
import { BlockbackService } from './blockback.service';
import { BnNgIdleService } from 'bn-ng-idle'; 
import { NavController, PopoverController } from '@ionic/angular';
import { ErroralertComponent } from './erroralert/erroralert.component';
import { Router } from '@angular/router';
import {fromEvent, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  login:any;
  isOpen : boolean = false;


  onlineEvent: Observable<Event> | undefined;
  offlineEvent: Observable<Event> | undefined;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string='';
  connectionStatus: string='';


  constructor( public BackBlock:BlockbackService,private bnIdle: BnNgIdleService,private popovercontroller:PopoverController,
    public nav:NavController,public router:Router) {
    // alert("work")
      // this.BackBlock.nobackbutton();

    //     this.login= sessionStorage.getItem('loginStatus')
    //   if(this.login == 'true'){
    // this.bnIdle.startWatching(3600).subscribe(async (res) => {
    //   if(this.router.url != '/login'){
    //     // alert("work")
    //   if(res) {
    //     // alert("work")
    //     if(this.isOpen == false){
    //       const popover = await this.popovercontroller.create({
    //         component: ErroralertComponent,
    //         cssClass: 'successpopup',
    //         translucent: true,
    //         backdropDismiss: false,
    //         componentProps: { ErrorMsg: 'Session expired Please login again' },
    //       });
    //       this.isOpen = true;
    //       await popover.present();
    //       await popover.onDidDismiss().then(() => {
    //         this.isOpen = false;
    //         sessionStorage.clear();
    //         this.nav.navigateBack('/login');
    //         this.popovercontroller.dismiss();
    //       })
    //     }
    //   }
    // }
    // })
  
    // }
  }



  async ngOnInit(): Promise<void> {
   
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
  
    this.subscriptions.push(this.onlineEvent.subscribe(async e => {
      const isModalOpened = await this.popovercontroller.getTop();
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      console.log(isModalOpened);
      if(isModalOpened != undefined){
      this.popovercontroller.dismiss();
      }
    }));
  
    this.subscriptions.push(this.offlineEvent.subscribe(async e => {
      const isModalOpened = await this.popovercontroller.getTop();
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      // console.log('Offline...');
      console.log(isModalOpened);
      if(isModalOpened != undefined){
      this.popovercontroller.dismiss();
      const popover = await this.popovercontroller.create({
        component: ErroralertComponent,
        cssClass: 'successpopup',
        translucent: true,
        backdropDismiss: false,
        componentProps:{ErrorMsg:'Check Your Internet Connection'}
      });
      await popover.present();
    }
    else{
      const popover = await this.popovercontroller.create({
        component: ErroralertComponent,
        cssClass: 'successpopup',
        translucent: true,
        backdropDismiss: false,
        componentProps:{ErrorMsg:'Check Your Internet Connection'}
      });
      await popover.present();
    }

    }));
  }



}
